import React from 'react';
import Helmet from 'react-helmet';
import Layout from '../components/layout';
import RepairPricingTable from '../components/pricing-tables/repair-pricing-table';

const IndexPage = () => (
  <Layout>
    <Helmet bodyAttributes={{ class: 'no-bg' }} />
    <section className="main-section main-section--center" id="cennik">
      <div className="container main-section__container">
        <div className="main-section__content" id="servis">
          <h1 className="main-section__title main-section__title--center" data-title="Servis">
            Servis
          </h1>
          <p className="main-section__text main-section__text--center">
            Nenechajte si pokaziť lyžovačku zle pripravenými lyžami, alebo snowboardom. Postaráme sa o ideálnu úpravu
            hrán, doplnenie a zrovnanie sklznice a samozrejme nanesenie vosku.
          </p>
          <RepairPricingTable />
          <div>
            <h2>Bootfitting</h2>
            <table>
              <tbody>
                <tr>
                  <td>Kúpené u Nás</td>
                  <td>15 &euro;</td>
                </tr>
                <tr>
                  <td>Nekúpené u Nás</td>
                  <td>20 &euro;</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="video-container">
          <iframe
            style={{ width: '100%' }}
            width="560"
            height="315"
            src="https://www.youtube-nocookie.com/embed/1EE5qBmv7DM?controls=0"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </section>
  </Layout>
);

export default IndexPage;
