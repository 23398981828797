import React, { VoidFunctionComponent } from 'react';
import repairPricingData from '../../data/repair-pricing.json';

const RepairPricingTable: VoidFunctionComponent = () => {
  return (
    <>
      <div>
        <table className="table is-active" role="tabpanel">
          <thead className="table__head">
            {repairPricingData.header.map((values) => (
              <tr className="table__row" key={values.toString()}>
                {values.map((value, index) => {
                  return (
                    <th className="table__cell" key={`${index}-${values.toString()}`}>
                      {value}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody className="table__body">
            {repairPricingData.body.map((values) => {
              return (
                <tr className="table__row" key={values.toString()}>
                  {values.map((value, index) => (
                    <td className="table__cell" key={`${index}-${values.toString()}`}>
                      {value}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="table-mark">Servisné úkony pri snowboardoch sa vykonávajú bez viazania</div>
      </div>
      <div style={{ marginTop: '20px', textAlign: 'center' }}>
        <a className="button button--primary" href="https://services.bookio.com/slyze/widget?lang=sk">
          Rezervovať termín
        </a>
      </div>
    </>
  );
};

export default RepairPricingTable;
